import { render, staticRenderFns } from "./registerForm.vue?vue&type=template&id=dc10fca2&scoped=true"
import script from "./registerForm.vue?vue&type=script&lang=js"
export * from "./registerForm.vue?vue&type=script&lang=js"
import style0 from "./registerForm.vue?vue&type=style&index=0&id=dc10fca2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc10fca2",
  null
  
)

export default component.exports